export default {
    primary: '#3773F5',
    secondary: {
        base: '#ffffff',
        darken1: '#eeeeee',
        darken2: '#cecece',
    },
    accent: '#3773F5',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    subnav: '#364462',
    accept: '#88DE31',
    reject: '#FC3844',
    forward: '#3773F5'
}
